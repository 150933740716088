import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextImage } from "@src/components";
import useCountdown from "@src/hooks/use-countdown";
import { useLayoutContext } from '@src/layouts/provider';
const Item = ({
  title,
  value
}) => <Stack flexDirection="column" alignItems="center" gap="8px" data-sentry-element="Stack" data-sentry-component="Item" data-sentry-source-file="not-start-flash-sale.tsx">
    <Stack justifyContent="center" alignItems="center" sx={{
    width: {
      xs: "60px",
      md: "100px"
    },
    height: {
      xs: "60px",
      md: "100px"
    },
    borderRadius: "10px",
    padding: "8px 10px",
    backgroundColor: "#F6F6F6",
    fontSize: {
      xs: "28px",
      md: "48px"
    },
    fontWeight: 500
  }} data-sentry-element="Stack" data-sentry-source-file="not-start-flash-sale.tsx">
      {value < 10 ? `0${value}` : value}
    </Stack>
    <Typography variant="body1" sx={{
    textAlign: "center",
    fontWeight: 500,
    fontSize: {
      xs: "14px",
      md: "16px"
    },
    lineHeight: "28px",
    textTransform: "uppercase"
  }} data-sentry-element="Typography" data-sentry-source-file="not-start-flash-sale.tsx">
      {title}
    </Typography>
  </Stack>;
const EmptyFlashSale = () => {
  const {
    config
  } = useLayoutContext();
  const {
    flash_sale
  } = config || {};
  const remainSeconds = flash_sale?.from ? (new Date(flash_sale.from).getTime() - new Date().getTime()) / 1000 : 0;
  const {
    seconds: secondRemain
  } = useCountdown(remainSeconds);
  const days = Math.floor(secondRemain / 86400);
  const hours = Math.floor(secondRemain % 86400 / 3600);
  const minutes = Math.floor(secondRemain % 3600 / 60);
  const seconds = secondRemain % 60;
  if (flash_sale?.isNotStarted) {
    return <Stack flexDirection="column" pb={6} spacing={2} overflow="auto" alignItems="center" gap="60px" margin="0px auto">
        <Stack direction="row" alignItems="center" justifyContent="center" gap={{
        xs: 2,
        md: 4
      }}>
          {days > 0 && <Item value={days} title="Ngày" />}
          <Item value={hours} title="Giờ" />
          <Item value={minutes} title="Phút" />
          <Item value={seconds} title="Giây" />
        </Stack>
        <Box sx={{
        width: "auto",
        height: {
          xs: 200,
          md: 280
        },
        margin: "0px auto !important"
      }}>
          <NextImage sx={{
          width: "auto",
          height: "100%",
          "& img": {
            position: "relative !important",
            width: "auto !important",
            objectFit: "cover"
          }
        }} src="/assets/images/empty-sale.png" />
        </Box>
        <Typography variant="body1" sx={{
        textAlign: "center",
        fontWeight: 700,
        fontSize: {
          xs: 18,
          md: 28
        },
        lineHeight: {
          xs: "24px",
          md: "32px"
        },
        margin: "0px auto !important"
      }}>
          Chương trình Flash Sale sắp diễn ra!
        </Typography>
      </Stack>;
  }
  return null;
};
export default EmptyFlashSale;