// use countdown input seconds return seconds
import React from "react"

const useCountdown = (timeLeave: number) => {
  const [seconds, setSeconds] = React.useState(0)

  React.useEffect(() => {
    if (timeLeave > 0) {
      setSeconds(Number(timeLeave.toFixed(0)))
    }
  }, [timeLeave])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(interval)
      } else {
        setSeconds(seconds => seconds - 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  return { seconds, setSeconds }
}

export default useCountdown
