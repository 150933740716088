import React from "react";
import Stack from "@mui/material/Stack";
import useCountdown from "@src/hooks/use-countdown";
const Item = ({
  value
}) => <Stack justifyContent="center" alignItems="center" sx={{
  minWidth: "44px",
  borderRadius: "10px",
  padding: "8px 10px",
  backgroundColor: "#F6F6F6",
  fontSize: "24px",
  fontWeight: 500
}} data-sentry-element="Stack" data-sentry-component="Item" data-sentry-source-file="index.tsx">
    {value < 10 ? `0${value}` : value}
  </Stack>;
const CountdownSale = ({
  remainSeconds
}) => {
  const {
    seconds: secondRemain,
    setSeconds: resetTimeout
  } = useCountdown(remainSeconds);
  const hours = Math.floor(secondRemain / 3600);
  const minutes = Math.floor(secondRemain % 3600 / 60);
  const seconds = secondRemain % 60;
  if (Number(remainSeconds) <= 0) {
    return null;
  }
  return <Stack direction="row" alignItems="center" justifyContent="center" gap="10px" data-sentry-element="Stack" data-sentry-component="CountdownSale" data-sentry-source-file="index.tsx">
      <Item value={hours} data-sentry-element="Item" data-sentry-source-file="index.tsx" />
      <Item value={minutes} data-sentry-element="Item" data-sentry-source-file="index.tsx" />
      <Item value={seconds} data-sentry-element="Item" data-sentry-source-file="index.tsx" />
    </Stack>;
};
export default CountdownSale;